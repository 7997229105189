<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <password-change
        @passwordChanged="$router.push({ name: 'login', params: {} })"
        :emailToken="token"
      />
    </v-col>
  </v-row>
</template>

<script>
import PasswordChange from "@/components/settings/PasswordChange";

export default {
  name: "PasswordChangeEmail",
  components: {
    PasswordChange,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
};
</script>
