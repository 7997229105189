<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("reset password") }}</v-toolbar-title>
      <v-spacer />
      <wiki slug="settings-password" />
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-form @submit="changePassword" onSubmit="return false;">
        <v-card-text>
          <validation-provider
            v-if="
              emailToken == null &&
              !(twoFactorEnabled || $store.getters.isStaff)
            "
            vid="current_password"
            :name="$t('current password')"
            :rules="
              twoFactorEnabled || $store.getters.isStaff
                ? 'max:50'
                : 'required|max:50'
            "
            v-slot="{ errors, valid, classes }"
          >
            <v-text-field
              name="current_password"
              v-model="currentPassword"
              :label="$t('current password')"
              :error-messages="errors"
              :success="valid"
              :type="showCurrentPassword ? 'text' : 'password'"
              :append-icon="showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :class="classes"
              @click:append="showCurrentPassword = !showCurrentPassword"
              :clearable="!isMobile"
              required
              autocomplete="off"
            />
          </validation-provider>
          <validation-provider
            vid="new_password"
            :name="$t('new password')"
            rules="required|confirmed:confirm_password|max:50"
            v-slot="{ errors, valid, classes }"
          >
            <v-text-field
              :label="$t('new password')"
              name="new_password"
              v-model="newPassword"
              :error-messages="errors"
              :success="valid"
              :type="showNewPassword ? 'text' : 'password'"
              :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :class="classes"
              @click:append="showNewPassword = !showNewPassword"
              :clearable="!isMobile"
              required
              autocomplete="off"
            />
          </validation-provider>
          <validation-provider
            vid="confirm_password"
            :name="$t('confirm password')"
            rules="required|max:50"
            v-slot="{ errors, valid, classes }"
          >
            <v-text-field
              :label="$t('confirm password')"
              name="confirm_password"
              v-model="confirmedPassword"
              :error-messages="errors"
              :success="valid"
              :type="showConfirmedPassword ? 'text' : 'password'"
              :append-icon="showConfirmedPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :class="classes"
              @click:append="showConfirmedPassword = !showConfirmedPassword"
              :clearable="!isMobile"
              required
              autocomplete="off"
            />
          </validation-provider>
          <validation-provider
            v-if="twoFactorEnabled"
            vid="token"
            :name="$t('two factor token')"
            rules="required|digits:6"
            v-slot="{ errors, valid, classes }"
          >
            <v-otp-input
              length="6"
              type="number"
              name="token"
              v-model="token"
              :label="$t('two factor token')"
              prepend-icon="mdi-two-factor-authentication"
              :error-messages="errors"
              :class="classes"
              :success="valid"
              autocomplete="none"
            />
          </validation-provider>
        </v-card-text>

        <v-card-text class="mt-n10 mb-n4" v-if="newPassword">
          <password-score v-model="score" :password="newPassword" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            name="changePassword"
            color="primary"
            :disabled="loading || invalid || score == null || score < 4"
            @click="changePassword()"
            text
          >
            {{ $t("change password") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import PasswordScore from "@/components/basics/PasswordScore";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "PasswordChange",
  mixins: [showErrors, isMobile],
  components: {
    PasswordScore,
    Wiki,
  },
  props: {
    emailToken: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      score: null,
      newPassword: "",
      showNewPassword: false,
      confirmedPassword: "",
      showConfirmedPassword: false,
      currentPassword: "",
      showCurrentPassword: false,
      token: "",
    };
  },
  computed: {
    twoFactorEnabled() {
      return this.$store.getters.twofa.enabled;
    },
  },
  methods: {
    changePassword() {
      var that = this;
      this.loading = true;
      var params = { new_password: this.newPassword };
      if (this.emailToken) {
        params["token"] = this.emailToken;
      } else if (this.token) {
        params["token"] = this.token;
      } else if (this.currentPassword) {
        params["current_password"] = this.currentPassword;
      }
      this.$http
        .post("login/change_password", params)
        .then(function (response) {
          that.$store.commit("setToken", response.data.token);
          that.currentPassword = "";
          that.newPassword = "";
          that.confirmedPassword = "";
          that.token = "";
          that.$refs.obs.reset();
          that.$snotify.success(response.data.detail);
          that.$emit("passwordChanged");
        })
        .catch(function (error) {
          if (error.unauthorized()) {
            // 401: means 2FA is enabled and token required
            that.$http.post("logout").finally(function () {
              that.$store.commit("resetSession");
              that.reloadSite();
            });
          } else {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          }
        })
        .finally(function () {
          that.score = null;
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "reset password": "Reset password",
    "current password": "Current password",
    "new password": "New password",
    "confirm password": "Confirm password",
    "two factor token": "Two factor token",
    "change password": "Change password",
    "go back": "Go back"
  },
  "de": {
    "reset password": "Passwort neu setzen",
    "current password": "Aktuelles Passwort",
    "new password": "Neues Passwort",
    "confirm password": "Passwort wiederholen",
    "two factor token": "Zwei Faktor PIN",
    "change password": "Passwort ändern",
    "go back": "zurück"
  }
}
</i18n>
